@import '../../styles/propertySets.css';

.root {
  width: 100%;
  padding: 48px 0 24px;

  background-color: var(--matterColorLight);
  /* box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 1%); */

  @media (--viewportMedium) {
    padding: 72px 0 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @apply --marketplacePageWidth;
}

/* Footer links */
.links {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  padding-bottom: 48px;
  margin-bottom: 24px;

  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 96px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 32px;
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;
  text-transform: capitalize;

  &:first-child {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    line-height: 24px;
    text-transform: capitalize;

    margin-top: 0;
    margin-bottom: 6px;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColorTextAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Font */
    @apply --marketplaceSmallFontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorText);
  }
}

.copyrightMobile {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorText);

  @media (--viewportMedium) {
    display: none;
  }
}

.socialLinks {
  display: flex;
}

.icon {
  /* Layout */
  display: flex;

  height: 20px;
  width: 20px;
  margin-right: 12px;

  & svg {
    height: 20px;
    width: 20px;
    fill: var(--matterColor);

    /* Animation */
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    & svg {
      fill: var(--marketplaceColor);
    }
  }

  &:last-child {
    margin-right: 0px;
  }
}
