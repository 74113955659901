@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorLight);

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    /* Page size */
    @apply --marketplacePageWidth;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 128px 0 82px 0;
    flex-direction: column;
    overflow-x: auto;
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 128px 0 82px 0;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panelWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /* Padding */
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 88px 0 82px 72px;
    border-left: 1px solid var(--matterColorNegative);
  }
}

.panel {
  @media (--viewportMedium) {
    max-width: 580px;
  }
}

.photosPanel {
  @media (--viewportMedium) {
    max-width: 720px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;

  margin: 0;
}

.profileUserContainer {
  display: none;

  @media (--viewportLarge) {
    display: unset;

    /* Page width */
    @apply --marketplacePageWidth;

    /* Position */
    position: relative;
  }
}

.profileUser {
  display: flex;
  align-items: center;

  /* Position */
  position: absolute;
  top: 48px;
}

.profileUserDisplayName {
  margin: 0;
}

.profileUserAvatar {
  margin-right: 12px;
}

.stripeConnectForm {
  margin-top: 0;
}
