@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 72px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    padding: 72px 48px 48px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;

  @media (--viewportMedium) {
    max-width: 410px;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;

  @media (--viewportMedium) {
    margin-top: 12px;
  }
}

.modalButton {
  margin-top: 48px;
}
