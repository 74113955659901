@import '../../styles/propertySets.css';

/* Tabs */
.root {
}

.tabNav {
  /* Layout */
  display: flex;
  align-items: center;
  overflow-x: scroll;

  box-shadow: var(--boxShadowLight);

  list-style: none;
  margin: 0 0 48px;

  @media (--viewportMedium) {
    overflow-x: unset;
    width: 100%;

    box-shadow: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--matterColorNegative);
  }
}

.tabNavHeading {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;

  margin-left: 24px;

  &:first-child {
    margin-left: 0;
    padding-left: 24px;

    @media (--viewportMedium) {
      padding-left: 0;
    }
  }

  &:last-child {
    padding-right: 24px;

    @media (--viewportMedium) {
      padding-right: 0;
    }
  }
}

.tabNavHeadingLink {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);
  letter-spacing: 0.05em;
  text-transform: capitalize;
  white-space: nowrap;

  border-bottom: 2px solid transparent;
  padding-bottom: 12px;

  @media (--viewportMedium) {
    border-bottom: 0px;
  }

  & .tabNavIcon {
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }
}

.tabNavHeadingSelectedLink {
  /* Font */
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  border-bottom: 2px solid var(--matterColor);

  @media (--viewportMedium) {
    border-bottom: none;
  }

  /* Bottom line */
  &:after {
    display: none;

    @media (--viewportMedium) {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: -2px;
      left: 0;
      background-color: var(--marketplaceColor);
    }
  }

  & .tabNavIcon {
    fill: var(--matterColor);
    stroke: var(--matterColor);
  }
}

.tabNavIcon {
  margin-right: 12px;
}

.tabNavAccountIcon {
  stroke: none !important;

  & path {
    fill: inherit;
    stroke: inherit;
  }
}

.tabNavPhotoIcon,
.tabNavReviewsIcon {
  fill: none !important;

  & path,
  & g {
    fill: inherit;
    stroke: inherit;
  }
}

.tab {
  width: 100%;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionContent {
  margin-bottom: 48px;
}

.sectionContentSkills {
  @media (--viewportMedium) {
    display: none;
  }
}

.sectionHeading {
  /* Font */
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightSemiBold);
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Margin & Padding */
  margin-top: 0px;
  margin-bottom: 24px;
}

.aboutList {
  margin: 0;
  list-style: none;
}

.aboutListItem {
  /* Font */
  font-weight: var(--fontWeightMedium);
  letter-spacing: 0.05em;
  text-transform: capitalize;

  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.aboutListItemAnswer {
  margin-left: 24px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    margin-left: 48px;
  }
}

.aboutBio,
.reviewsBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  /* Font */
  color: var(--matterColor);
  letter-spacing: 0.05em;
  line-height: 32px;
  margin: 0;

  @media (--viewportMedium) {
    max-width: 600px;
  }
}

.reviewsBio {
  margin-top: 12px;
}

.photosContainer {
  min-width: 100%;
  width: 0;
}

.photosHeading {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photosSlider {
  position: relative;
}

.photosWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.photosImage {
  position: relative;

  /* Animation */
  cursor: pointer;

  &:hover {
    & .photosImageOverlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

.photosImageOverlay {
  /* Overlay position */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  /* Layout for icon */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Animation */
  opacity: 0;
  visibility: hidden;
  transition: all var(--transitionStyleButton);
}

.tabReviewsButton {
  max-width: 200px;
  margin-top: 24px;
}

/* Modal setup for image carousel */
.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

/* Slider arrows */
.arrowIconContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: calc(-20px - 24px);
    transform: translate(0, -50%);
    cursor: pointer;

    &:hover {
      & .arrowIcon path {
        stroke: var(--marketplaceColor);
      }
    }
  }
}

.arrowIcon {
  fill: none;

  & path {
    /* Animation */
    transition: all var(--transitionStyleButton);
  }
}

.mobileArrowIconContainer {
  @media (--viewportLarge) {
    display: none;
  }
}

.mobileArrowIcon {
}

.userSkillsList,
.staffList {
  margin: 0;
  list-style: none;
}

.userSkillsItem,
.staffItem {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  font-weight: var(--fontWeightRegular);
  letter-spacing: 0.05em;

  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.userSkillsItem {
  text-transform: capitalize;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewAttributes {
  max-width: 400px;

  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);
  line-height: 2;
}

.reviewAttribute {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  line-height: 1.7;

  &:last-child {
    margin-right: 0px;
  }
}

.reviewAttributeStar {
  height: 12px;
  width: 12px;
}

.reviewAttributeName {
  text-transform: capitalize;

  margin: 0 5px;
}

.link {
  text-transform: none;
}
