.root {
}

.root path,
.root circle {
  fill: none;
  stroke: var(--matterColorAnti);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.9;
}
