.root {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportLarge) {
    padding-top: 12px;
    flex-direction: column;
  }
}

.tab {
  /* Layout */
  display: flex;
  align-items: center;

  margin-left: 16px;
  &:first-child {
    margin-left: 0;
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.listingTabTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.listingTabImage {
  height: 32px;
  width: 32px;
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  object-fit: cover;
}
