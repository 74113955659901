@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.titleWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-top: 24px;
    margin-bottom: 38px;
    padding: 7px 0 7px 0;
  }
}

.title {
  color: var(--matterColor);
  margin: 0;
}

.stripeBox {
  user-select: none;
  flex: 1;
}

.stripeBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  padding: 24px;
  cursor: pointer;
}

.stripeBoxHeading {
  display: flex;
  align-items: center;
  margin: 0;
}

.stripeBoxContent {
  padding: 24px;

  border-left: 1px solid var(--matterColorNegative);
  border-right: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
}

.stripeBoxChevron {
  height: 14px;
  width: 14px;

  /* Animation */
  transition: all 0.15s ease-in-out;
}

.stripeBoxChevronActive {
  transform: rotate(180deg);
}

.stripeBoxBadge {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;

  padding: 6px 8px;
  background-color: var(--matterColorNegative);
  border-radius: 4px;
}

.stripeBoxBadgeConnected {
  background-color: var(--successColor);
  color: var(--matterColorLight);
}

.payoutButtonContainer {
}

.payoutButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: calc(96px - 12px);
  }
}

.tooltip {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-left: 24px;
  }
}

.tip {
  margin: 0;

  @media (--viewportMedium) {
    max-width: 300px;
  }
}
