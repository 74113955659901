@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0;
  }
}

.headingSale {
  margin-top: 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  display: none;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    display: block;
    margin: 123px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 24px 48px -6px;
  }
}

.detailCardTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 12px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 24px 48px 48px;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 48px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 48px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 48px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 48px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.actionButton {
  text-transform: capitalize;
  margin: 0;
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    margin-bottom: 24px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionsButtons,
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionsButtons,
.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

.desktopPaymentButtons {
  @media (--viewportLarge) {
    margin-top: -12px;
    margin-bottom: 12px;
  }
}

.desktopCompletedButtons {
  @media (--viewportLarge) {
    margin-top: -12px;
  }
}

.mobilePaymentAndDeclineButtons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.enquiryPanel {
  margin: 0 24px;

  @media (--viewportLarge) {
    margin: 24px 48px 48px 48px;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.showDocument {
  padding: 0 24px 24px;
  margin-top: -24px;
  user-select: none;

  @media (--viewportLarge) {
    padding: 24px 48px 0;
    margin-top: 0;
  }
}

.showDocumentLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Font */
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;

  /* Animation */
  cursor: pointer;
}

.showDocumentIcon {
  transition: all 0.15s ease-in-out;
}

.showDocumentIconActive {
  transform: rotate(180deg);
}

.transactionDocument {
  line-height: 24px;
  margin-top: 12px;
}

.transactionDocumentLink {
  @apply --marketplaceSmallFontStyles;
}

.buttonWithTooltip {
  display: flex;
  align-items: center;
}

.tooltipContainer {
  margin: 0 12px;
}

.tooltip {
  width: 280px;
}

.tooltipIcon {
  height: 20px;
  width: 20px;
}

.diminishedActionButtonRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 24px;
  margin-bottom: 48px;

  @media (--viewportLarge) {
    align-items: center;
    padding: 0;
  }
}

/* e.g. dispute "link" */
.diminishedActionButton {
  @apply --marketplaceTinyFontStyles;
  width: max-content;
  color: #808080;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0px 75px;
  }
}

.counterOfferSeparator {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  margin-top: -36px;
  margin-bottom: 12px;

  /* Font */
  color: var(--matterColorAnti);
}

.desktopCounterOfferActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -12px;
    margin-bottom: 48px;
  }
}

.counterOfferActionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.counterOfferActionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  & button {
    text-transform: capitalize;
  }

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.counterOfferActionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.counterOfferActionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

.detailInfoCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailInfoCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    padding: 48px;
    margin: 0;
  }
}

.detailInfoCardTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.detailInfoCardListings {
  margin: 24px 0 0;
  padding: 0;
}

.detailInfoCardListing {
  position: relative;
  left: 0;
  margin-bottom: 6px;

  transition: all 0.15s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    left: 12px;
  }
}

.arrow {
  margin-left: 12px;

  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}

.mobileSaleButtons {
  display: flex;
  gap: 12px;
}

/* Tabs */
.tabs {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;
  overflow-x: scroll;

  /* Style */
  list-style: none;

  padding: 0 24px;
  margin: 48px 0;

  @media (--viewportMedium) {
    /* Layout */
    overflow-x: unset;
    gap: 32px;
    width: 100%;
    margin: 24px 0 48px;
  }

  @media (--viewportLarge) {
    padding: 0;
    margin: 148px 0 48px 0;
  }
}

.tab {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;
}

.tabLink {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);
  letter-spacing: 0.05em;
  white-space: nowrap;

  border-bottom: 2px solid transparent;
  padding-bottom: 12px;

  @media (--viewportMedium) {
    border-bottom: 0px;
  }

  & .tabIcon path {
    stroke: var(--matterColorAnti);
  }
}

.tabLinkSelected {
  /* Font */
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  border-bottom: 2px solid var(--marketplaceColor);

  & .tabIcon path {
    stroke: var(--marketplaceColor);
  }
}

.tabIcon {
  height: 22px;
  width: 22px;
  margin-right: 12px;
}

.tabLinkNotification {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.tabLinkNotificationIcon {
  height: 18px;
  width: 18px;

  & circle,
  & path {
    stroke-width: 1.5 !important;
  }

  & circle {
    &:first-of-type {
      fill: var(--marketplaceColor) !important;
      stroke: var(--marketplaceColor) !important;
    }
    &:last-of-type {
      fill: none !important;
      stroke: var(--matterColorLight) !important;
    }
  }

  & path {
    stroke: var(--matterColorLight) !important;
  }
}

.dailyLogsContent {
  position: relative;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.dailyLogsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dailyLogsSubmitWrapper {
  width: 100%;
  margin-bottom: 24px;
}

.dailyLogsButton {
  max-width: 240px;
}

.dailyLogsMessage {
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;

  margin: 0;
  padding: 24px;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border: 1px solid var(--attentionColor);
  border-radius: 4px;
}

.dailyLogsMessageIcon {
  flex-shrink: 0;
  margin-top: 2px;

  & g,
  & circle,
  & path {
    stroke: var(--attentionColor) !important;
  }
}

.dailyLogs {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  padding: 0;
  margin: 0;
}

.log {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.logDateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.logDate {
  /* Font */
  font-size: 24px;
  line-height: 36px;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 0;
}

.logView {
  text-decoration: underline;
  text-underline-position: under;

  transition: color var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.logNotes {
  margin-top: 0;
  margin-bottom: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.logAttachments {
  display: flex;
  align-items: center;
  gap: 12px;

  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);
}

.logAttachmentsIcon {
  fill: none;

  & g {
    stroke-width: 1.2;
  }
}

.logDateSmall {
  font-weight: var(--fontWeightMedium);
  color: var(--attentionColor);
}

.infoContent {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.infoContentList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.infoContentListItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  font-weight: var(--fontWeightRegular);
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  & > span {
    font-weight: var(--fontWeightSemiBold);
  }
}

.infoContentMessage {
  margin-top: 0;
  margin-bottom: 0;
}
