@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;
}

.subTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 10px;
}

.text {
  margin-top: 19px;
  margin-bottom: 0;
  color: var(--matterColorText);

  text-align: center;

  @media (--viewportMedium) {
    /* Size */
    max-width: 420px;
  }
}

.cards {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 48px;

  @media (--viewportMedium) {
    position: relative;
    z-index: 1;
    max-width: 900px;

    margin-left: auto;
    margin-right: auto;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 34px;
    margin-top: 64px;
  }
}

.card {
  /* Size */
  padding: 32px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: 0px 26px 49px rgb(146 165 184 / 25%);
  border-radius: 14px;

  /* Animation */
  transition: all 0.15s ease;

  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
    box-shadow: 14px 22px 52px -12px rgba(127, 127, 127, 0.15);

    /* Apply only on desktop */
    &:hover {
      background-color: var(--marketplaceColorUltraLight);
      box-shadow: 14px 22px 52px -12px rgba(127, 127, 127, 0.2);
      transform: scale(1.05);

      & .iconWrapper {
        background: var(--matterColorLight);
      }

      & .cardIconDesktop {
        fill: var(--marketplaceColor);

        & path {
          fill: var(--marketplaceColor);
        }
      }
    }
  }
}

.cardContent {
  padding-top: 6px;
}

.cardTitle {
  margin-top: 0;
  margin-bottom: 0;

  line-height: 26px;
  text-transform: capitalize;
}

.cardText {
  color: var(--matterColorText);

  margin-top: 14px;
  margin-bottom: 0px;
}

.iconWrapper {
  /* Layout */
  display: none;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  height: 60px;
  width: 60px;
  margin-bottom: 14px;

  background: linear-gradient(
    180deg,
    var(--marketplaceColor) 0%,
    var(--marketplaceColorLight) 100%
  );
  box-shadow: 0px 10px 22px -2px rgba(241, 90, 36, 0.15);
  border-radius: 24px;

  /* Animation */
  transition: all 0.15s ease;

  @media (--viewportMedium) {
    display: flex;
    height: 66px;
    width: 66px;

    margin-right: 36px;
    margin-bottom: 0px;
    box-shadow: 0px 10px 22px -2px rgba(241, 90, 36, 0.3);
  }
}

.gradientWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  width: 48px;
  margin-bottom: 14px;

  background: linear-gradient(180deg, #ffc0a9 0%, #f15b26 100%);
  box-shadow: 0px 10px 22px -2px rgba(241, 90, 36, 0.15);
  border-radius: 50%;
}

.cardIconDesktop {
  fill: var(--matterColorLight);

  /* Animation */
  transition: all 0.15s ease-in-out;

  & g,
  & path {
    /* Animation */
    transition: all 0.15s ease;
  }
}

.cardIconMobile {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);

  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0 0 14px;

  @media (--viewportMedium) {
    display: none;
  }
}

.gearImage {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Position */
    position: absolute;
    bottom: 0;
    right: 0;

    /* Style */
    height: 450px;
    object-fit: cover;
    opacity: 0.2;
  }
}
