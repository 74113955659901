.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
  border-radius: 4px;
}

.mapRoot {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
