@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-top: 24px;

  @media (--viewportMedium) {
    margin: 38px 0 0;
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.field {
  margin: 0 0 48px;
  @media (--viewportLarge) {
    margin: 0 0 24px;
  }

  & label {
    padding: 0;
  }

  & input {
    border-bottom-color: var(--marketplaceColor);
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.submitButtonContainer {
  margin-top: 48px;
  padding-bottom: 72px;

  @media (--viewportLarge) {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.submitButton {
  min-height: 58px;

  @media (--viewportLarge) {
    max-width: 192px;
  }
}

.documentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  @media (--viewportLarge) {
    margin-bottom: 48px;
  }
}

.addDocumentWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Firefox doesn't support documents aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 72px;
}

.addDocument {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 24px;
  text-align: center;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseDocumentText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseDocument {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.documentTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addDocumentInput {
  display: none;
}

.documentRequiredWrapper {
  width: 100%;
  clear: both;
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip {
  max-width: 280px;
}

.tooltipIcon {
  height: 22px;
  width: 22px;
}

.enquiryBreakdown {
  margin-top: 48px;

  @media (--viewportLarge) {
    margin-top: -24px;
    margin-bottom: 24px;
  }
}
