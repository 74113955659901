@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 12px;

  /* Disable user selection */
  user-select: none;
}

.uknownPriceInput {
  /* Layout */
  display: flex;
  align-items: center;
  height: 100%;

  /* Style */
  background: var(--matterColorBright);
  box-shadow: var(--boxShadow);
  padding: 0 12px;
  border-radius: 2px;

  & span {
    white-space: nowrap;
  }
}

.field {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.priceInput {
  width: 100%;

  & > div {
    position: absolute;
  }
}

.priceInputDisabled {
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.5;
}

.sliderFieldsWrapper {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.sliderFieldsTitle {
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 18px;
}

.sliderFields {
  @media (--viewportMedium) {
    padding: 0 24px;
  }
}

.sliderItem {
  padding: 18px;
  background-color: var(--matterColorBright);
  box-shadow: var(--boxShadow);
  border: 1px solid var(--matterColorBright);
  border-radius: 2px;

  margin-top: 12px;
  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.sliderLabel {
  /* Layout */
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* Font */
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);

  padding: 0 0 0 13.5px;
  margin-bottom: 12px;
}

.sliderIcon {
  height: 17px;
  width: 17px;
  margin-right: 6px;
}

.showMore {
  @apply --marketplaceSmallFontStyles;
  color: var(--marketplaceColor);

  margin-top: 12px;
  margin-bottom: 12px;

  /* Animation */
  cursor: pointer;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 96px;
  }
}

.bills {
  margin-top: 48px;
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.billsTable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #f3f3f3;
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    display: inline-block;
    overflow-x: unset;
    white-space: unset;
  }

  & thead {
    background: #f3f3f3;
  }
  & th,
  & td {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    text-align: left;
    white-space: nowrap;

    width: 170px;
    max-width: 174px;

    margin-top: 0;
    margin-bottom: 0;

    padding: 12px;
  }
  & td {
    font-weight: var(--fontWeightRegular);

    /* Limit one line */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.billsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
}

.addBill {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 16px;
}
