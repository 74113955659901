@import '../../styles/propertySets.css';

.root {
  /* Style */
  background: var(--matterColorLight);
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  border-radius: 14px;
  padding: 24px;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    /* Remove link's hover effect */
    &:hover {
      text-decoration: none;
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.wrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.avatar {
  position: relative;

  height: 96px;
  width: 96px;

  @media (--viewportMedium) {
    height: 142px;
    width: 142px;
  }
}

.avatarWithRing {
  &:after {
    @media (--viewportMedium) {
      content: url('./images/ring.svg');
      position: absolute;
      margin-top: 8px;
    }
  }
}

.displayNameContainer {
  margin: 24px 0;
  text-align: center;
}

.displayName {
  margin: 0;
  color: var(--matterColor);
}

.company {
  font-size: 14px;
  font-weight: 400;
  color: var(--matterColorText);
}

.skills {
  display: block;
  align-self: flex-start;
  margin-bottom: 24px;
}

.skill {
  /* Layout */
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 8px;

  /* Style */
  background: var(--marketplaceColorUltraLight);
  border-radius: 12px;
  padding: 4px 8px;

  /* Font */
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorText);
}

.learnMoreButton {
  /* Style */
  background: var(--marketplaceColor);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  /* Font */
  text-transform: capitalize;
  font-weight: var(--fontWeightMedium);

  /* Size */
  max-width: 105px;
  min-height: 30px;
  margin-top: auto;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
