@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  color: var(--matterColor);

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 7px 0 7px 0;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  @media (--viewportMedium) {
    flex-basis: 800px;
    min-height: auto;
    height: auto;
  }
}
