@import '../../styles/propertySets.css';

/* Animation for the hero elements */
@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  @apply --marketplacePageWidth;

  /* Child layout */
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 1024px) {
    align-items: flex-end;
    flex-direction: row;
  }

  @media (min-width: 1124px) {
    margin-top: -124px;
  }
}

.leftContent {
}

.rightContent {
  margin-top: 48px;

  @media (min-width: 1024px) {
    margin-top: 0px;
    padding-left: 112px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColor);
  margin: 0;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: 480px;
  }
}

.heroSubTitle {
  composes: animation;
  animation-delay: 0.8s;

  color: var(--matterColor);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  max-width: 410px;
  margin: 24px 0;

  @media (min-width: 1024px) {
    margin: 18px 0 42px;
    font-size: 18px;
    line-height: 28px;
  }
}

.heroButtons {
  display: flex;
  align-items: center;

  composes: animation;
  animation-delay: 0.8s;
}

.heroButton,
.projectButton {
  @apply --marketplaceButtonStyles;

  /* Font */
  text-transform: capitalize;

  @media (--viewportMedium) {
    display: block;
    width: 162px;
  }
}

.heroBrowseButton {
  /* Style */
  background-color: transparent;
  border: 1px solid var(--marketplaceColor);
  margin-left: 12px;

  /* Font */
  color: var(--marketplaceColor);

  &:hover {
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin-left: 18px;
  }
}

.projectButton {
  composes: animation;
  animation-delay: 0.8s;

  width: 100%;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 18px;
    width: 340px;
  }
}

.badge {
  composes: animation;
  animation-delay: 0.2s;

  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 12px;
  color: var(--marketplaceColor);

  /* Style */
  background: #fcded2;
  border-radius: 8px;
  width: fit-content;

  /* Layout */
  padding: 12px 29px;
  margin: 0 0 18px;
}

.videoImage {
  display: none;

  @media (min-width: 1024px) {
    display: unset;
    width: 100%;
  }
}

.videoImageMobile {
  width: 100%;
  max-height: 300px;
  object-fit: cover;

  @media (min-width: 1024px) {
    display: none;
  }
}

.iconAndTitle {
}

.heroIcon {
  height: 72px;
  width: 72px;
  margin-bottom: 24 px;

  @media (--viewportMedium) {
    height: 96px;
    width: 96px;
    margin-bottom: 18px;
    margin-left: 96px;
  }
}
