.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.field {
  margin-bottom: 24px;
  /* padding: 24px;
  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius); */

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.submitButtons {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  margin-top: 48px;
  margin-bottom: 0;

  & .submitButton {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    grid-template-columns: calc(40% - 12px) calc(60% - 12px);

    margin-top: 36px;
    margin-bottom: 0;

    & .submitButton {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}

.removeButton {
  margin-top: 0;
  margin-bottom: 0;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
  }
}

.billsTable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #f3f3f3;
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    display: inline-block;
    overflow-x: unset;
    white-space: unset;
  }

  & thead {
    background: #f3f3f3;
  }
  & th,
  & td {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    text-align: left;
    white-space: nowrap;

    width: 170px;
    max-width: 174px;

    margin-top: 0;
    margin-bottom: 0;

    padding: 12px;
  }
  & td {
    font-weight: var(--fontWeightRegular);

    /* Limit one line */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.trInputs td {
  padding-top: 0;
}

.addBill {
  /* margin-top: 12px; */
  width: fit-content;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto 0;
}

.deleteButton svg {
  height: 16px;
  width: 16px;

  & path {
    stroke: var(--matterColor) !important;
  }
}

.billsLabel {
  margin-bottom: 12px;
}
