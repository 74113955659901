@import '../../styles/propertySets.css';

.root {
}

.discountContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Animation */
  user-select: none;
  cursor: pointer;

  /* Animation */
  opacity: 1;
  transition: all 0.15s ease-in-out;
}

.discountContainerApplied {
  /* Make not accessible */
  opacity: 0.3;
  pointer-events: none;
}

.addDiscountText {
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;
}

.iconArrow {
  transition: all.15s ease-in-out;
}

.iconArrowShow {
  transform: rotate(-180deg);
}
