@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
}

.serviceLocations {
}

.serviceLocationsTitle {
  /* Layout */
  display: flex;
  align-items: center;

  margin-top: 28px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.serviceLocationsCount {
  color: var(--matterColorAnti);
}

.serviceLocationsFields {
  padding: 0 24px;
}

.addServiceLocationButtonContainer {
  margin-top: 12px;
}

.addServiceLocationButton {
  display: flex;
  align-items: center;
}

.addServiceLocationButtonDisabled {
  color: var(--matterColorAnti);
  cursor: no-drop !important;

  & .addServiceLocationIcon {
    fill: var(--matterColorAnti);

    & path {
      stroke: var(--matterColorAnti);
    }
  }
}

.addServiceLocationIcon {
  /* Position */
  position: relative;
  bottom: 1px;

  margin-right: 8px;
}

.serviceLocationField {
  margin-top: 24px;
}

.serviceLocationLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.removeIconContainer {
  display: flex;
  cursor: pointer;

  &:hover {
    & .removeIcon {
      fill: var(--matterColor);
    }
  }
}

.removeIcon {
  fill: var(--matterColorAnti);
  stroke: none;
  height: 12px;
  width: 12px;
}

.serviceLocation {
  flex: 1;
}

.tooltipContainer {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-left: 8px;
    margin-bottom: 1px;
  }
}

.tooltip {
  @apply --marketplaceBodyFontStyles;
  font-size: 14px;
  line-height: 24px;

  margin: 0;

  @media (--viewportMedium) {
    max-width: 300px;
  }
}

.tooltipIcon {
  height: 18px;
  width: 18px;
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 96px;
  }
}
