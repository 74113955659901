@import '../../styles/propertySets.css';

:root {
  --facebook: #3b5998;
  --twitter: #00acee;
  --whatsapp: #25d366;
  --linkedin: #0e76a8;
  --email: #7f7f7f;
  --phone: #7f7f7f;
}

.shareDropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--matterColorLight);
  margin-top: 48px;

  & > button,
  & > .localButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* Font */
    @apply --marketplaceSmallFontStyles;
    font-size: 15px;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorLight);
    text-align: center;

    padding: 8px 20px;
    margin: 0 0 12px;

    /* Style */
    min-height: auto;
    width: 100%;
    max-width: 300px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;

    & svg {
      margin-right: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.facebook {
  background: var(--facebook);

  & svg {
    & path {
      fill: var(--facebook);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.twitter {
  background: var(--twitter);

  & svg {
    & path {
      fill: var(--twitter);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.whatsapp {
  background: var(--whatsapp);

  & svg {
    & path {
      fill: var(--whatsapp);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.linkedin {
  background: var(--linkedin);

  & svg {
    & path {
      fill: var(--linkedin);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.email {
  background: var(--email);

  & svg {
    & path {
      fill: var(--email);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.facebook,
.twitter,
.linkedin,
.whatsapp,
.email {
  & svg {
    & circle {
      fill: var(--matterColorLight);
    }
  }
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

.phone {
  background: var(--phone);

  & svg {
    height: 8px;

    & path {
      stroke: var(--phone) !important;
      stroke-width: 1.3;
    }
  }
}

.copy svg {
  fill: none;
}

.localIconWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 19px;
  width: 19px;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: 50%;

  margin-right: 8px;
}

.localIcon {
  margin-right: 0 !important;
}
