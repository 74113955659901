@import '../../styles/propertySets.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  @media (--viewportMedium) {
    & ul {
      column-count: 2;
      margin-top: 6px;
    }
  }
}

.featuresTextClass {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Position */
  position: relative;
  bottom: 1px;
}

.otherOptionContainer {
  position: relative;
  margin-top: 18px;
}

.otherOptionBadge {
  /* Position */
  position: absolute;
  right: 0;
  top: 0;

  /* Style */
  background: var(--marketplaceColor);
  padding: 4px 4px 6px;
  border-radius: 4px;
  pointer-events: none;

  /* Font */
  font-size: 10px;
  line-height: 1;
  padding-bottom: 6px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
}

.error {
  color: var(--failColor);
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 96px;
  }
}
