@import '../../styles/propertySets.css';

.root {
}

.discountForm {
  margin-top: 12px;
}

.discountLabel {
  padding-top: 0px;
}

.fieldWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;
}

.inputWrapper {
  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  position: relative;
}

.input {
  /* Font */
  @apply --marketplaceSmallFontStyles;

  padding-right: 22px;
}

.errorIcon {
  position: absolute;
  right: 0;
}

.submitButton {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Size */
  min-height: 32px;
  width: 100px;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
