@import '../../styles/propertySets.css';

:root {
  --avatarSize: 96px;
  --avatarSizeDesktop: 148px;
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 28px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
  }
}

.name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  /* Form margins */
  composes: formMargins;
}

.description,
.phoneNumber,
.company,
.contractor,
.crew,
.companyName {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
}

.otherOptionContainer {
  position: relative;
  margin-top: 18px;
}

.otherOptionBadge {
  /* Position */
  position: absolute;
  right: 0;
  top: 0;

  /* Style */
  background: var(--marketplaceColor);
  padding: 4px 4px 6px;
  border-radius: 4px;
  pointer-events: none;

  /* Font */
  font-size: 10px;
  line-height: 1;
  padding-bottom: 6px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
}

.profileImage {
  /* Form margins */
  composes: formMargins;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
}

.label {
  width: var(--avatarSize);
  @media (--viewportMedium) {
    width: var(--avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--avatarSize);
  height: var(--avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorLight);
  border-radius: calc(var(--avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--avatarSizeDesktop);
    height: var(--avatarSizeDesktop);
    border-radius: calc(var(--avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.features {
  composes: formMargins;

  & > legend {
    margin-bottom: 8px;
  }
}

.featuresTextClass {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Position */
  position: relative;
  bottom: 1px;
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 96px;
  height: 38px;
  padding: 0 0 0 32px;

  /* Layout */
  display: flex;
  align-items: center;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--avatarSize);
  height: var(--avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--avatarSizeDesktop);
    height: var(--avatarSizeDesktop);
    border-radius: calc(var(--avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.operator {
  composes: formMargins;
  flex-shrink: 0;
}

.operatorLabel {
  margin-bottom: 8px;
}

.phoneNumberHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updatePhoneLink {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
  text-underline-position: under;
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: calc(96px - 12px);
  }
}
