@import '../../styles/propertySets.css';

.root {
  position: relative;
  background-color: var(--marketplaceColor);
  text-align: center;

  /* Position and size */
  padding: 13px 24px;
}

.notice {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);

  /* Position */
  margin: 0;
}

.closeContainer {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
  padding: 12px;

  /* Align */
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    & .close {
      opacity: 1;
    }
  }
}

.close {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);

  /* Size */
  height: 10px;
  width: 10px;

  /* Animation */
  opacity: 0.8;
  transition: 0.15s opacity ease-in-out;
}
