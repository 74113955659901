@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 72px;
  padding-bottom: 48px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  display: none;

  @apply --marketplaceModalIconStyles;

  /* Size */
  height: 48px;
  width: 48px;
}

.modalIcon g path {
  stroke: var(--marketplaceColor);
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.logDate {
  color: var(--marketplaceColor);
}

.logList {
  list-style: none;
  padding: 0;
  margin: 48px 0 0;
}

.logItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  margin-bottom: 24px;
  padding-bottom: 24px;

  /* Style */
  border-bottom: 1px solid var(--matterColorNegative);

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 24px;

    @media (--viewportMedium) {
      padding-bottom: 0;
    }
  }
}

.logItemTitle,
.logItemText {
  margin-top: 0;
  margin-bottom: 0;
}

.logItemTitle {
  font-weight: var(--fontWeightSemiBold);
}

.logItemText {
}

.logAttachments {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.logAttachment {
  position: relative;

  height: 145px;
  width: 100%;

  background-color: var(--matterColorNegative);
  border-radius: 2px;

  & a {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &:hover {
    & .logAttachmentImageOverlay {
      opacity: 1;
    }
  }
}

.logAttachmentImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.logAttachmentImageOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  transition: all var(--transitionStyleButton);
  opacity: 0;
}

.logAttachmentImageOverlayIcon {
  transform: scale(0.7);
}
