@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;

    & * {
      &:after {
        display: none;
      }
    }
  }
}

.avatarContainer {
  margin-right: 24px;

  & :after {
    content: '';

    /* Size */
    height: calc(calc(100% - 48px) + 24px);
    width: 2px;

    /* Position */
    position: absolute;
    bottom: -24px;
    left: 24px;
    z-index: -1;

    /* Style */
    background-color: #edeff1;

    @media (--viewportMedium) {
      height: calc(calc(100% - 60px) + 24px);
      left: 30px;
    }
  }
}

.avatar {
  height: 48px;
  width: 48px;

  @media (--viewportMedium) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 680px) {
    & span {
      font-size: 16px !important;
    }
  }
}

.invitationContent {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  border-radius: 4px;
  padding: 12px;

  @media (--viewportMedium) {
    padding: 24px;
  }
}

.invitationMessage {
  margin: 0;
}

.highlighted {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorHeading);
}

.listingLink {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
}

.invitationBottom {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 12px 0 0;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.invitationInfo {
  display: flex;
  align-items: center;
}

.invitationDate {
  display: flex;
  align-items: center;

  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);

  margin: 0;
}

.conversationActionButton {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--marketplaceColor);

  margin: 0;
  cursor: pointer;
}

.removeInvitationContainer {
  display: flex;
  align-items: center;

  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);

  margin-left: 12px;

  /* Animation */
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: var(--matterColor);

    & .removeInvitationIcon path {
      stroke: var(--matterColor);
    }
  }
}

.disabledRemoveInvitation,
.disableConversationActionButton {
  cursor: no-drop;
}

.removeInvitationIcon {
  margin-right: 6px;
}

.removeInvitationIcon path {
  stroke: var(--matterColorAnti);

  /* Animation */
  transition: all 0.1s ease-in-out;
}

.dateIcon {
  margin-right: 6px;
}

.dateIcon path {
  stroke: var(--matterColorAnti);
}

.spinnerIcon {
  height: 16px;
  width: 16px;

  margin-right: 6px;
}

.errorMessage {
  /* Font */

  @apply --marketplaceSmallFontStyles;
  color: var(--failColor);

  /* Layout */
  align-self: flex-end;
  margin: 12px 0 0;
}
