@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Layout for child components */
  display: flex;
  align-items: center;

  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* fill */
  background-color: var(--matterColorLight);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  /* Animation */
  transition: all var(--transitionStyleButton);
}

.topbarLandingPage {
}

.topbarScroll {
  background-color: var(--matterColorLight);
  border-bottom-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);
}

.topbarWrapper {
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* Layout */
  @apply --marketplacePageWidth;
}

/* logo */
.logoLink {
  display: flex;
  height: 100%;
}

.logo {
}

.logoAndSearchContainer {
  display: flex;
  align-items: center;
}

/* Search */
.searchLink {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftMargin {
  margin-left: 24px;
  height: var(--topbarSearchHeightDesktop);

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.topbarSearch {
  background-color: var(--matterColorLight);
  border-radius: 4px;
  min-width: 211px;
  padding: 0 12px;
  box-shadow: var(--boxShadow);
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navigation {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* Size */
  width: 100%;
}

.navigationLink {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorTextAnti);
  font-weight: var(--fontWeightMedium);
  letter-spacing: 0.01em;
  text-transform: capitalize;

  /* Space */
  margin-right: 32px;
  /* Remove space at last item */
  &:last-child {
    margin-right: 0px;
  }

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    color: var(--matterColor);
  }
}

.navigationLinkActive {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.navigationLinkProfessionals {
  margin-left: 32px;
}

.navigationButton {
  /* Button */
  @apply --marketplaceButtonStyles;

  /* Font */
  line-height: 1;

  /* Custom size */
  height: 50px;
  max-width: 123px;

  &:hover {
    color: var(--matterColorLight);
  }
}

/* Profile menu */
.profileMenuLabel {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  margin: 0;
  padding: 0;

  &:active {
    border-bottom: 0;

    & .avatarArrow {
      /* Color */
      fill: var(--matterColor);
    }
  }

  &:hover {
    & .avatarArrow {
      /* Color */
      fill: var(--matterColor);
    }
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }

  & .avatarArrow {
    fill: var(--matterColor);
  }
}

.navigationMenu {
  position: relative;
  width: auto;
  height: auto;
}

.navigationMenuLabel {
  width: auto;
  height: auto;
  padding: 0;

  margin-top: 14px;
  margin-bottom: 14px;
  margin-right: 0;

  &:active {
    & .avatarArrow {
      fill: var(--matterColor);
    }
  }

  &:hover {
    & .avatarArrow {
      fill: var(--matterColor);
    }
  }
}

.navigationMenuIsOpen {
  color: var(--matterColor);

  & .avatarArrow {
    fill: var(--matterColor);
  }
}

.navigationMenuContent {
  min-width: 200px !important;
  padding: 20px 0;
  margin-right: 8px;
}

.avatar {
  margin: 16px 0;
}

.avatarArrow {
  height: 10px;
  width: 10px;
  margin-left: 8px;

  /* Color */
  fill: var(--matterColorTextAnti);
  stroke: none;

  /* Animation */
  transition: all var(--transitionStyleButton);
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
  margin-right: 8px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  text-transform: capitalize;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}
