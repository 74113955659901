@import '../../styles/propertySets.css';

.root {
  margin: 0;
  max-width: 740px;
}

.reviewItem {
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 24px;
  margin-bottom: 24px;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.review {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 24px;
}

.reviewContent {
  /* Font */
  font-size: 15px;
  line-height: 24px;
  color: var(--matterColor);
  letter-spacing: 0.05em;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 24px 0 0;
}

.reviewInfo {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);
  line-height: 1;

  display: inline;

  margin-top: 0;
  margin-bottom: 0px;
}

.reviewWrapper {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);
  line-height: 1;

  max-width: 500px;

  margin-top: 6px;
  margin-bottom: 0px;
}

.separator {
  margin: 0 5px;
}

.desktopSeparator {
  margin: 0 5px;
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
}

.desktopReviewRating {
  white-space: nowrap;

  position: relative;
  bottom: 1.2px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewAttributes {
  display: inline;
}

.reviewAttribute {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  line-height: 1.7;

  &:last-child {
    margin-right: 0px;
  }
}

.reviewAttributeStar {
  height: 12px;
  width: 12px;
}

.reviewAttributeName {
  text-transform: capitalize;
  margin: 0 5px;
}
