@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.leftContent {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.rightContent {
  margin-bottom: 48px;
  text-align: center;

  @media (--viewportMedium) {
    max-width: 450px;
    margin: 0 auto 48px;
  }

  @media (--viewportLarge) {
    /* Font */
    text-align: left;

    padding-left: 90px;
    margin: 0 0 48px;
    max-width: unset;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    max-width: 380px;
  }
}

.subTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 10px;
}

.text {
  margin-top: 12px;
  margin-bottom: 0;
  color: var(--matterColorText);

  @media (--viewportLarge) {
    /* Size */
    max-width: 365px;
  }
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.card {
  /* Style */
  padding: 40px;
  background-color: var(--matterColorLight);
  box-shadow: 0px 26px 49px rgb(146 165 184 / 25%);
  border-radius: 14px;

  @media (--viewportMedium) {
    box-shadow: 0px 26px 49px rgba(146, 165, 184, 0.15);
  }
}

.reviewRating {
  text-align: left;
  display: flex;
  align-items: center;
}

.reviewStar {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.reviewRatingCount {
  /* Font */
  font-size: 16px;
  line-height: 2px;
  font-weight: var(--fontWeightMedium);
}

.reviewUser {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.avatarImage {
  height: 24px;
  width: 24px;

  /* Style */
  border-radius: 50%;
  border: 1px solid var(--matterColorLight);
  box-shadow: -5px 4px 14px rgba(151, 151, 151, 0.28);
  object-fit: cover;
}

.avatarDisplayName {
  /* Font */
  font-size: 16px;
  line-height: 18px;
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 16px;
}

.reviewText {
  margin: 24px 0 0;
  max-width: 274px;
  color: var(--matterColorTextAnti);
}

.cardInfo {
  margin-top: 24px;

  &:hover {
    & .cardInfoText {
      color: var(--marketplaceColorLight);
    }

    & .rightArrow {
      & path {
        stroke: var(--marketplaceColorLight);
      }
    }
  }
}

.cardInfoText {
  display: flex;
  align-items: center;

  /* Font */
  color: var(--marketplaceColor);
  text-transform: capitalize;
}

.rightArrow {
  margin-left: 8px;
  fill: none;

  /* Position */
  position: relative;
  top: 1px;
}

.reviewsButtonDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    max-width: 192px;
    margin-top: 24px;
  }
}

.reviewsButtonMobile {
  max-width: 192px;
  margin-top: 24px;
  align-self: flex-end;

  @media (--viewportLarge) {
    display: none;
  }
}
