@import '../../styles/propertySets.css';

.root {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.leftContent {
  flex: 1;
}

.rightContent {
  display: none;
  padding-left: 48px;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    flex: 1;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  text-transform: capitalize;
  max-width: 500px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    /* Size */
    max-width: 450px;
  }
}

.subTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 10px;
}

.text {
  margin-top: 16px;
  margin-bottom: 0;
  color: var(--matterColorText);

  @media (--viewportMedium) {
    /* Size */
    max-width: 406px;
  }
}

.services {
  margin-top: 36px;
  margin-bottom: 0;

  padding-top: 0;
  padding-bottom: 0;

  @media (--viewportMedium) {
    column-count: 2;
  }
}

.service {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.serviceIcon {
  margin-right: 12px;
}

.smallImages {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-left: 24px;
}

.mainImage {
  position: relative;
  bottom: 12px;

  /* Layout */
  width: 100%;
  height: auto;
}
