@import '../../styles/propertySets.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.filterButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  color: var(--matterColorTextSecondary);
  font-weight: var(--fontWeightRegular);
  text-transform: capitalize;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  white-space: nowrap;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: 0px 2px 4px rgb(186 186 186 / 70%);
  border-radius: 10px;
  border: none;

  &:focus {
    color: var(--matterColor);
    outline: none;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.filterButtonActive {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  white-space: nowrap;

  /* Style */
  background-color: var(--marketplaceColor);
  box-shadow: 0px 2px 4px rgb(186 186 186 / 70%);
  border-radius: 10px;
  border: none;

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }

  &:focus {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
    box-shadow: var(--boxShadowFilterButton);
  }
}
