@import '../../styles/customMediaQueries.css';

.root {
  float: left;
  position: relative;
  overflow: hidden;

  /* Reset button */
  border: none;
  padding: 0;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.aspectRatioWrapper,
.multipleFilesAspectRatio {
  position: relative;
  padding-bottom: calc(100% * (2 / 3));
}

.label,
.labelDropFile {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 24px;
  text-align: center;

  /* Colors */
  background-color: var(--matterColorLight);

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }

  @media (--viewportMedium) {
    padding: 0;
  }
}

.labelDropFile {
  background-color: var(--matterColorBright);
  border-style: solid;
  border-color: var(--matterColor);
}

.dropzoneInput {
  display: none;
}
