@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  max-width: 600px;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-top: 24px;
}

.error {
  color: var(--failColor);
}

.attentionMessage {
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;

  margin: 0 0 24px;
  padding: 18px 24px;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border: 1px solid var(--attentionColor);
  border-radius: 4px;

  @media (--viewportMedium) {
    margin: 0 0 32px;
  }
}

.attentionMessageIcon {
  flex-shrink: 0;
  margin-top: 2px;

  & g,
  & circle,
  & path {
    stroke: var(--attentionColor) !important;
  }
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: 48px;
  margin-bottom: 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 72px;
    margin-bottom: 0;
  }
}
