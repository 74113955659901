.root {
  display: flex;
  flex-direction: column;
}

.rootWithMap {
  & .listingCards {
    @media (--viewportMedium) {
      grid-template-columns: 1fr;
    }

    @media (--viewportLarge) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & .listingCard {
    padding: 24px;
  }
}

.listingCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listingCard {
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  padding: 48px 0 0;

  @media (--viewportMedium) {
    padding: 48px 48px 0;
  }
}
