@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 580px;

  margin-top: 24px;
  padding: 18px;

  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
  border-radius: 4px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.notificationBoxText {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}
