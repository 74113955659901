@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  color: var(--matterColorTextSecondary);
  font-weight: var(--fontWeightRegular);
  text-transform: capitalize;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  white-space: nowrap;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: 0px 2px 4px rgb(186 186 186 / 70%);
  border-radius: 10px;
  border: none;

  &:focus {
    color: var(--matterColor);
    outline: none;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  white-space: nowrap;

  /* Style */
  background-color: var(--marketplaceColor);
  box-shadow: 0px 2px 4px rgb(186 186 186 / 70%);
  border-radius: 10px;
  border: none;

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
  }

  &:focus {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
