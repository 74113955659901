@import '../../styles/propertySets.css';

.root {
  margin-bottom: 48px;

  & p {
    @apply --marketplaceBodyFontStyles;
  }
  & h3 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 34px 0 12px 0;

    @media (--viewportMedium) {
      margin: 34px 0 0 0;
    }
  }
  & ul {
    list-style: initial;
    padding-left: 48px;
    & li {
      @apply --marketplaceBodyFontStyles;
      margin: 0;
    }
  }
  & strong {
    font-weight: var(--fontWeightSemiBold);
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}
