@import '../../styles/propertySets.css';

.root {
  margin-top: 48px;
}

.listingsWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.listingWrapper {
  /* Size */
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid var(--matterColorNegative);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.listingContentWrapper {
  /* Layout */
  display: grid;
  grid-template-columns: 70% auto;
  align-items: center;
}

.listingContent {
  display: flex;
  align-items: center;

  & a {
    display: none;

    @media (--viewportMedium) {
      display: flex;
    }
  }
}

.listingInfo {
  padding-right: 24px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }
}

.listingTitle {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;

  /* Text decoration */
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
}

.listingLocation {
  @apply --marketplaceSmallFontStyles;
}

.listingImage {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Size */
    width: 90px;
    height: 60px;

    /* Style */
    object-fit: cover;
    border-radius: 4px;
  }
}

.arrowButton {
  max-height: 50px;
  box-shadow: 0px 10px 22px rgba(241, 90, 36, 0.26);

  &:hover {
    box-shadow: 0px 10px 22px rgba(241, 90, 36, 0.1);

    /* On disabled remove box-shadow */
    &:disabled {
      box-shadow: none;
    }
  }
}

.error {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--failColor);

  /* Remove paragraph margin */
  margin: 0;

  /* Layout */
  width: 100%;
  padding-top: 12px;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -24px;
}

.loadingIcon {
  height: 48px;
  width: 48px;
}

.buttonWrapper {
  margin-top: 72px;
}

.buttonWrapperText {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorAnti);
  text-align: center;

  margin: 0 0 12px;
}
