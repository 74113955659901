.root {
  & path {
    fill: none;
    stroke: var(--marketplaceColor);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
}
