@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;
}

.subTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 10px;
}

.text {
  margin-top: 19px;
  margin-bottom: 0;
  color: var(--matterColorText);

  text-align: center;

  @media (--viewportMedium) {
    /* Size */
    max-width: 420px;
  }
}

.cards {
  /* Layout */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin-top: 48px;

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 64px;
  }
}

.card {
  /* Card styles */
  box-shadow: 0px 26px 49px rgb(146 165 184 / 15%);

  /* Animation */
  transition: all 0.15s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 14px 22px 52px -12px rgb(127 127 127 / 20%);
  }
}

.bottomWrapper {
  /* Layout */
  display: flex;
  justify-content: flex-end;

  width: 100%;
  margin-top: 24px;
}

.bottomLink {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorText);

  text-transform: capitalize;
  text-decoration: underline;
  text-underline-position: under;

  /* Animation */
  transition: color 0.15s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}
