@import '../../styles/propertySets.css';

:root {
  --buttonHeight: 92px;
}

.userTypeButtons {
  /* Layout */
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
  border-radius: 4px;
  min-height: var(--buttonHeight);
  width: 100%;

  /* Remove left line while active */
  & > div.typeButtonActive:before,
  & > div:hover:before,
  & > div.typeButtonActive + div:before {
    background-color: transparent;
  }
}

.typeButton {
  /* Layout */
  display: flex;
  align-items: center;
  position: relative;
  padding: 11px 28px;
  user-select: none;
  border: solid 2px transparent;
  cursor: pointer;
  width: 50%;
  height: var(--buttonHeight);
  transition: all 0.15s;

  /* Font styles */
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--matterColorText);

  &:nth-child(1) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: -2px;
      width: 1px;
      background-color: #e0e0e0;
    }
  }

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    & .tooltipIcon g > * {
      stroke: var(--matterColorLight) !important;
    }
  }
}

.typeButtonActive {
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  & .tooltipIcon g > * {
    stroke: var(--matterColorLight) !important;
  }

  &:hover {
    background-color: var(--marketplaceColor);
  }
}

.tooltip {
  max-width: 240px;
}

.tooltipIconContainer {
  display: none;

  @media (--viewportMedium) {
    position: absolute;
    top: 12px;
    right: 12px;

    display: flex;
    cursor: default;
  }
}

.tooltipIcon {
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-bottom: 1px;

  transition: all 0.15s;

  & g > * {
    transition: all 0.15s;
  }
}
