@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;

  padding: 0;
}

.label,
.altLabel {
  @apply --marketplaceSearchFilterLabelFontStyles;
  color: var(--matterColorTextSecondary);
  font-weight: var(--fontWeightRegular);
  white-space: nowrap;

  margin-left: 0;
  margin-right: 8px;
}

.altLabel {
  margin-left: 8px;
  margin-right: 0;
}
