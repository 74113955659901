@import '../../styles/propertySets';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 32px;
  min-height: 420px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid var(--matterColorLight);
  border-radius: 4px;
}

.rootHighlighted {
  & .name {
    color: var(--marketplaceColor);
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.activeIconWrapper {
  display: flex;
  opacity: 0.8;
}

.name {
  color: var(--matterColorDark);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;
}

.price {
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);

  margin-top: 8px;
  margin-bottom: 0;
}

.savingPriceDisabled {
  text-decoration: line-through;
}

.savingPrice {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
}

.features {
  margin-top: 0;
  margin-bottom: 24px;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.featureName {
  text-transform: capitalize;
}

.checkIcon {
  height: 18px;
  width: 18px;

  margin-right: 12px;
}

.bottom {
  margin-top: auto;
}

.submitButton {
  text-transform: uppercase;
}

.submitButtonNotice {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;

  margin-top: 12px;
  margin-bottom: 0;
}

.errorMessage {
  color: var(--failColor);
  text-align: center;

  padding: 0 12px;
  margin-bottom: 12px;
}

.toastifyMessage {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);
  opacity: 0.8;

  margin-top: 0;
  margin-bottom: 0;
}

.toastifyMessagePlan {
  text-transform: capitalize;
}
