@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
}

.timeframe {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.otherOptionContainer {
  position: relative;
  margin-top: 18px;
}

.otherOptionBadge {
  /* Position */
  position: absolute;
  right: 0;
  top: 0;

  /* Style */
  background: var(--marketplaceColor);
  padding: 4px 4px 6px;
  border-radius: 4px;
  pointer-events: none;

  /* Font */
  font-size: 10px;
  line-height: 1;
  padding-bottom: 6px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 96px;
  }
}

.homeAddress {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  & label {
    display: flex;
    align-items: center;
  }
}

.tooltip {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-left: 12px;
  }
}

.tip {
  @apply --marketplaceBodyFontStyles;
  font-size: 14px;
  line-height: 24px;

  margin: 0;

  @media (--viewportMedium) {
    max-width: 300px;
  }
}

.tooltipIcon {
  height: 18px;
  width: 18px;
}
