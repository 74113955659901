.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.errorPlaceholder {
  color: var(--failColor);

  @media (--viewportMedium) {
    margin: 0;
    width: 100%;
    min-height: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;
  background-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}
