@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 28px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.phoneNumber {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.category {
}

.otherOptionContainer {
  position: relative;
  margin-top: 18px;
}

.otherOptionBadge {
  /* Position */
  position: absolute;
  right: 0;
  top: 0;

  /* Style */
  background: var(--marketplaceColor);
  padding: 4px 4px 6px;
  border-radius: 4px;
  pointer-events: none;

  /* Font */
  font-size: 10px;
  line-height: 1;
  padding-bottom: 6px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
}

.features {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-top: 36px;

    & ul {
      margin-top: 6px;
    }
  }
}

.featuresTextClass,
.preferencesTextClass {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Position */
  position: relative;
  bottom: 1px;
}

.preferencesTextClass {
  bottom: 0;
  text-transform: initial;
}

.preferences {
  margin-top: 28px;
  margin-left: 0px;

  & label {
    padding: 0;
  }

  @media (--viewportMedium) {
    margin-top: 36px;

    & ul {
      margin-top: 8px;
    }
  }
}

.submitButton {
  /* EditListingPage button */
  @apply --editListingPageButton;
  text-transform: capitalize;

  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: calc(96px - 12px);
  }
}

.phoneNumberHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updatePhoneLink {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
  text-underline-position: under;
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.specialNotes {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  & label {
    display: flex;
    align-items: center;
  }
}

.tooltip {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-left: 12px;
  }
}

.tip {
  @apply --marketplaceBodyFontStyles;
  font-size: 14px;
  line-height: 24px;

  margin: 0;

  @media (--viewportMedium) {
    max-width: 300px;
  }
}

.tooltipIcon {
  height: 18px;
  width: 18px;
}
