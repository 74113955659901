@import '../../styles/propertySets.css';

.root {
}

.label {
  margin-bottom: 12px;
}

.addImages {
  /* Layout */
  display: inline-block;
  width: 100%;
  padding: 12px;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  border-radius: 3px;
}

.addImagesWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  /* Style */
  border-width: 2px;
  border-radius: 2px;
  border-color: var(--matterColorNegative);
  border-style: dashed;
  background-color: var(--matterColorBright);

  /* Animation */
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
}

.addImagesWrapperDisabled {
  opacity: 0.6;
  cursor: no-drop;
}

.addImagesMessage {
  /* Font */
  composes: marketplaceSmallFontStyles from global;

  margin-top: 0;
  margin-bottom: 0;
}

.thumbsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;

  margin-top: 12px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.thumb {
  position: relative;

  /* Layout */
  display: inline-flex;
  padding: 4px;
  height: 100px;
  width: 100%;

  /* Style */
  border-radius: 2px;
  border: 1px solid var(--matterColorNegative);
}

.thumbInner {
  display: flex;
  overflow: hidden;
  background-color: var(--matterColorBright);
  width: 100%;
}

.thumbImage {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.browseMessage {
  color: var(--marketplaceColor);
}

.removeContainer,
.downloadContainer {
  /* Position */
  position: absolute;
  display: flex;
  right: 12px;
  top: 12px;

  /* Style */
  background: var(--matterColorLight);
  border-radius: 4px;

  opacity: 0.6;
  padding: 4px;

  /* Animation */
  cursor: pointer;
  transition: opacity var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.downloadContainer {
  right: unset;
  left: 12px;
}

.removeIcon,
.downloadIcon {
  height: 8px;
  width: 8px;
}

.downloadIcon {
  stroke-width: 3;
}
