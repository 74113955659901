@import '../../styles/propertySets.css';

.root {
}

.content {
  /* Style */
  background-color: var(--matterColorLight);
  border-radius: 14px;
  box-shadow: 0px 26px 49px rgb(146 165 184 / 25%);

  @media (--viewportMedium) {
    background-image: url('./bg.png');
    background-position: 0;
    border-radius: 8px;
    box-shadow: 0px 26px 49px rgb(146 165 184 / 14%);
  }
}

.upperContent {
  padding: 30px;
}

.bottomContent {
  padding: 0 30px 30px;

  @media (--viewportMedium) {
    padding: 0 30px 30px;
  }

  @media (--viewportLarge) {
    padding: 30px 60px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  text-transform: capitalize;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    max-width: 500px;
  }
}

.subTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    text-transform: capitalize;
  }
}

.cards {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 30px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
}

.card {
  /* Style */
  position: relative;
  padding: 24px;
  background: var(--marketplaceColor);
  border-radius: 8px;
  box-shadow: 14px 22px 52px -12px rgb(127 127 127 / 10%);

  /* Animation */
  transition: all 0.15s ease;

  &:last-child {
    & .cardText {
      max-width: 208px;
    }
  }

  @media (--viewportMedium) {
    &:hover {
      background: var(--matterColorLight);
      box-shadow: var(--boxShadowMedium);

      & .cardTitle,
      & .cardText {
        color: var(--marketplaceColor);
      }

      & .fireworksIcon g {
        fill: var(--marketplaceColor);
      }
    }
  }
}

.cardTitle {
  color: var(--matterColorLight);
  line-height: 26px;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;

  /* Animation */
  transition: all 0.15s ease;
}

.cardText {
  /* Font */
  color: var(--matterColorLight);
  line-height: 24px;

  margin-top: 18px;
  margin-bottom: 0px;

  /* Animation */
  transition: all 0.15s ease;
}

.fireworksIcon {
  position: absolute;
  top: 8px;
  right: 0;

  /* Animation */
  transition: all 0.15s ease;

  @media (--viewportMedium) {
    top: 17px;
  }
}
