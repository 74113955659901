@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

.field {
  margin: 22px 0 24px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  margin-top: 48px;
}

.submitButton {
  max-width: 234px;
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
